<template>
  <chat type="audio" />
</template>

<script>
import Chat from "../chat-room/Index";

export default {
  components: {
    Chat,
  },
};
</script>